@import '../utils';

.skills-section,
.skills-section__skill {
  @include pageGrid(1);
}

.skills-section__skill {
  grid-column: 1 / -1;

  row-gap: var(--eb-spacer-sm);

  & + .skills-section__skill {
    padding-top: var(--eb-spacer-3xl);
  }

  &:not(:last-of-type) {
    padding-bottom: var(--eb-spacer-3xl);
    border-bottom: 1px solid var(--eb-border-tertiary);
  }
}

.skills-section__skill-name {
  font-weight: var(--eb-font-weight-medium);
  color: var(--eb-foreground-secondary);
}

.skills-section__skill-points {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin-top: var(--eb-spacer-xs);

    &:first-of-type {
      margin-top: 0;
    }
  }
}

@media (min-width: 800px) {
  .skills-section,
  .skills-section__skill {
    @include pageGrid();
  }

  .skills-section__title {
    grid-column: 2 / span 2;
  }

  .skills-section__skill-name {
    grid-column: 1;
    justify-self: end;
  }

  .skills-section__skill-points {
    grid-column: 2 / span 2;
  }
}
