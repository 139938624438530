@import './utils';

$font-sizes: (
  1: 3xl,
  2: 2xl,
  3: xl,
);

@each $size, $name in $font-sizes {
  .h#{$size} {
    font-size: var(--eb-accent-#{$name}-font-size);
    line-height: var(--eb-accent-#{$name}-line-height);
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    font-family: var(--eb-accent-font-family);
    font-weight: var(--eb-font-weight-bold);
    margin-top: var(--eb-spacer-3xl);
    margin-bottom: var(--eb-spacer-lg);

    @if ($i <= 2) {
      display: inline-block;
      position: relative;

      &:before {
        @include highlight(primary, 8px);
      }
    }
  }
}

.primary-font {
  font-family: var(--eb-primary-font-family);
}

.accent-font {
  font-family: var(--eb-accent-font-family);
}

h1 {
  font-size: var(--eb-accent-3xl-font-size);
  line-height: var(--eb-accent-3xl-line-height);
}

h2 {
  font-size: var(--eb-accent-2xl-font-size);
  line-height: var(--eb-accent-2xl-line-height);
}

h3 {
  font-size: var(--eb-accent-xl-font-size);
  line-height: var(--eb-accent-xl-line-height);
}

a {
  color: currentColor;
}

p,
ul {
  margin: var(--eb-spacer-sm) 0;
}

ul {
  padding-left: var(--eb-spacer-xl);
}

li + li {
  margin-top: var(--eb-spacer-sm);
}

strong,
b {
  font-weight: var(--eb-font-weight-medium);
}

.align-start {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-end {
  text-align: right;
}
