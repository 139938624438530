.social-media {
  display: flex;
  flex-direction: row;
  gap: var(--eb-spacer-3xl);

  position: relative;
  width: max-content;
  margin: var(--eb-spacer-lg) auto;
  padding: 0 var(--eb-spacer-lg);

  font-family: var(--eb-accent-font-family);
  font-size: var(--eb-accent-link-font-size);

  &::before,
  &::after {
    position: absolute;
  }

  &::before {
    content: '[';
    left: 0;
  }

  &::after {
    content: ']';
    right: 0;
  }
}
