@mixin highlight($type: primary, $size: 8px) {
  content: '';
  position: absolute;
  left: -$size;
  right: -$size;
  bottom: $size;
  height: $size;
  border-radius: 4px;
  z-index: -1;

  background-color: var(--eb-highlight-#{primary});
}

@mixin pageGrid($columns: 3) {
  display: grid;
  justify-content: center;
  align-items: start;
  grid-template-columns: repeat($columns, 1fr);
  column-gap: clamp(var(--eb-spacer-3xl), 8dvw, var(--eb-spacer-4xl));
}
