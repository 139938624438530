.hero {
  position: relative;
  width: 100%;
  height: 70vh;
  height: 70dvh;
  max-height: 500px;
  padding: var(--eb-spacer-2xl);

  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  grid-template-rows: 3fr 1fr 1.8fr 1.2fr;
  grid-template-areas: '.' 'slogan' 'link' 'media';
  gap: var(--eb-spacer-3xl);
}

.hero__slogan {
  grid-area: slogan;

  h1 {
    margin-top: 0;
    margin-bottom: var(--eb-spacer-md);
  }

  p {
    margin: 0;
    line-height: var(--eb-accent-base-line-height);
  }
}

.hero__link {
  grid-area: link;

  align-self: self-end;
  font-size: var(--eb-accent-link-font-size);
}

.social-media {
  grid-area: media;
}
