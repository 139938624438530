@import './variables';
@import './typography.scss';
@import './core.scss';

@import './components/navigation.scss';
@import './components/social-media.scss';
@import './components/footer.scss';

@import './sections/default-page.scss';
@import './sections/hero.scss';
@import './sections/about.scss';
@import './sections/skills.scss';
@import './sections/recent-projects.scss';
@import './sections/contact.scss';
