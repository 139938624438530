@import './utils';

.projects-section {
  @include pageGrid();

  &__title {
    grid-column: 2 / span 2;
  }
}

.projects-section__content {
  grid-column: 2;

  font-size: var(--eb-accent-xl-font-size);
  line-height: var(--eb-accent-xl-line-height);
}
