@import './utils';

.default-page {
  @include pageGrid(1);

  width: inherit;

  &__content {
    max-width: var(--eb-max-text-block-size);
  }
}

@media (min-width: 800px) {
  .default-page {
    @include pageGrid();

    &__content {
      grid-column: 2 / span 2;
    }
  }
}
