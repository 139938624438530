@import './utils';

.about-section {
  @include pageGrid(1);

  &__image {
    display: none;
    justify-self: end;

    display: block;
    width: 100%;
    height: auto;
  }

  &__content {
    margin-top: var(--eb-spacer-3xl);
    max-width: var(--eb-max-text-block-size);
  }
}

@media (min-width: 800px) {
  .about-section {
    @include pageGrid();

    &__image {
      display: block;
    }

    &__content {
      grid-column: 2 / span 2;
    }
  }
}
