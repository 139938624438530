.header {
  position: absolute;
  position: sticky;
  top: 0;
  left: -1px;
  right: -1px;

  background-color: var(--eb-background-secondary);
  padding: var(--eb-spacer-lg) 0;
  border-bottom: 1px solid var(--eb-border-tertiary);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: clamp(var(--eb-spacer-lg), 4dvw, var(--eb-spacer-2xl));

  &__item {
    text-decoration: none;
    position: relative;
    z-index: 0;
    display: block;
    padding: 4px;

    font-size: 14px;
    line-height: 18px;

    color: var(--eb-foreground-primary);

    $lineAnimation: 600ms cubic-bezier(1, -0.43, 0.32, 0.92);
    $lineAnimationDelay: 300ms;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 6px;
      height: 4px;
      border-radius: 4px;
      z-index: -1;

      background-color: var(--eb-highlight-secondary);
      transition: left $lineAnimation, right $lineAnimation;
      transition-delay: $lineAnimationDelay;
    }

    &::before {
      left: 0;
      right: 100%;
    }

    &::after {
      left: 100%;
      right: 0;
    }

    &:hover {
      &::before,
      &::after {
        transition: left $lineAnimation, right $lineAnimation;
        transition-delay: 0;
      }

      &::before {
        left: 10px;
        right: 20%;
      }

      &::after {
        left: 20%;
        right: 10px;
      }
    }

    &--active {
      &::before,
      &::after {
        opacity: 1;
        transition-delay: $lineAnimationDelay;
      }

      &::before {
        left: -4px;
        right: 20%;
      }

      &::after {
        left: 20%;
        right: -4px;
      }
    }
  }
}
