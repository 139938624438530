.footer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
  gap: var(--eb-spacer-md);
  margin: var(--eb-spacer-2xl) 0;
}

.footer__nav {
  display: flex;
  align-items: center;
  gap: var(--eb-spacer-lg);
}

@media (min-width: 800px) {
  .footer {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: var(--eb-spacer-4xl);
  }
}
