*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-padding-top: 90px;
  scroll-behavior: smooth;
  background-color: var(--eb-background-secondary);
}

body {
  font-family: var(--eb-primary-font-family);
  font-size: var(--eb-primary-base-font-size);
  line-height: 1.5;
  color: var(--eb-foreground-primary);
  margin: 0 auto;
  padding: 0 var(--eb-spacer-lg);

  display: grid;
  justify-content: center;
  align-items: start;
  row-gap: var(--eb-spacer-4xl);

  width: var(--eb-content-page-size);
}

code,
pre {
  font-family: Menlo, Consolas, 'Consolas for Powerline', 'Courier New', Courier, monospace;
  background-color: #2b2b2b;
  color: #fff;
  padding: 0.25em;
}

@media (min-width: 800px) {
  body {
    margin: 0 auto;
    padding: 0 var(--eb-spacer-3xl);
  }
}
