:root {
  --eb-primary-font-family: PT Root UI, 'Segoe UI', Roboto, 'San Francisco', Arial, sans-serif;
  --eb-accent-font-family: PT Mono, Consolas, Menlo, 'Roboto Mono', 'Courier New', monospace;

  --eb-content-page-size: calc(100vw - var(--eb-spacer-2xl) * 2);
  --eb-max-text-block-size: 690px;
}

:root {
  /**
  ========================
  Collection 1
  ========================
  */
  --eb-background-primary: #ffffff;
  --eb-background-secondary: #fffdf7;
  --eb-border-tertiary: #f3f3f3;
  --eb-foreground-primary: #111111;
  --eb-foreground-secondary: #7c7c7c;
  --eb-highlight-primary: #fff61680;
  --eb-highlight-secondary: #ffbb0e80;
  --eb-spacer-2xl: 24px;
  --eb-spacer-2xs: 4px;
  --eb-spacer-3xl: 32px;
  --eb-spacer-3xs: 2px;
  --eb-spacer-4xl: 64px;
  --eb-spacer-lg: 16px;
  --eb-spacer-md: 12px;
  --eb-spacer-none: 0px;
  --eb-spacer-sm: 8px;
  --eb-spacer-xl: 20px;
  --eb-spacer-xs: 6px;

  /**
  ========================
  Typography
  ========================
  */
  --eb-accent-2xl-font-size: 2.4rem;
  --eb-accent-2xl-letter-spacing: 0.0048rem;
  --eb-accent-2xl-line-height: auto;
  --eb-accent-3xl-font-size: 2.8rem;
  --eb-accent-3xl-letter-spacing: 0.0056rem;
  --eb-accent-3xl-line-height: auto;
  --eb-accent-base-font-size: 1.4rem;
  --eb-accent-base-letter-spacing: 0rem;
  --eb-accent-base-line-height: 1.8rem;
  --eb-accent-link-font-size: 1.4rem;
  --eb-accent-link-letter-spacing: 0rem;
  --eb-accent-link-line-height: 1.8rem;
  --eb-accent-xl-font-size: 1.6rem;
  --eb-accent-xl-letter-spacing: 0.0032rem;
  --eb-accent-xl-line-height: auto;
  --eb-font-weight-bold: 700;
  --eb-font-weight-medium: 500;
  --eb-font-weight-regular: 400;
  --eb-primary-base-font-size: 1.4rem;
  --eb-primary-base-letter-spacing: 0rem;
  --eb-primary-base-line-height: 2rem;
  --eb-primary-xl-font-size: 1.6rem;
  --eb-primary-xl-letter-spacing: 0rem;
  --eb-primary-xl-line-height: 2.4rem;
}
