@import './utils';

.contact-section {
  @include pageGrid(1);
}

.contact-section__content {
  font-size: var(--eb-accent-xl-font-size);
  line-height: var(--eb-accent-xl-line-height);
}

.contact-section__cta {
  text-align: center;
  display: block;
  margin-top: var(--eb-spacer-4xl);
  margin-bottom: var(--eb-spacer-2xl);
  font-family: var(--eb-accent-font-family);
  font-size: var(--eb-accent-2xl-font-size);
}

@media (min-width: 800px) {
  .contact-section {
    @include pageGrid();
  }

  .contact-section__title {
    grid-column: 2 / span 2;
  }

  .contact-section__content {
    grid-column: 2;
  }
}
